'use strict';

/**
 @module Pending
*/

module.exports = Pending;

/**
 * Initialize a new `Pending` error with the given message.
 *
 * @param {string} message
 */
function Pending(message) {
  this.message = message;
}
